
"use strict";

var mainWorkspace;

var delete_all_blocks = function() {
  mainWorkspace.getTopBlocks().forEach(function(el) {
    el.dispose();
  });
};

var parse_code = function() {
  delete_all_blocks();
  var javascript_code = document.getElementById("code").value;
  var mode = document.getElementById("modeSelect").value;
  var xmlDom = Blocklify.JavaScript.importer.codeToDom(javascript_code, mode);
  Blockly.Xml.domToWorkspace(xmlDom, mainWorkspace);
};

var parse_blocks = function() {
  var output = document.getElementById("code");
  output.value = Blockly.JavaScript.workspaceToCode(mainWorkspace);
};

var exampleCode =
  "var product = 1;\n" +
  "var factorial = 1;\n" +
  "var A = 10;\n" +
  "var B = 4;\n" +
  "\n" +
  "for (var count = 0; count < B; count++) {\n" +
  "  product = product * A;\n" +
  "}\n" +
  "if (B < 0) {\n" +
  "  factorial = 0;\n" +
  "} else {\n" +
  "  for (var i = 1; i <= A; i++) {\n" +
  "    factorial = factorial * i;\n" +
  "  }\n" +
  "}\n";
