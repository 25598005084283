
'use strict';

/**
 * Name space for Blocklify JavaScript dependencies.
 */
goog.provide('Blocklify.JavaScript.Blocks');


Blocklify.JavaScript.Blocks.setMutators = function(block, mutatorsList) {
  for (var i = 0; i < mutatorsList.length; i++) {
    Blocklify.JavaScript.Blocks.mutators[mutatorsList[i].name](block, mutatorsList[i]);
  }
};